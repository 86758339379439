<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-card>
          <v-card-title>購買支払-新規</v-card-title>
          <v-divider></v-divider>
          <v-card-actions class="mt-4">
            <v-row align="center">
              <v-col
                cols="12"
                md="2"
              >
                <label>仕入先コード</label>
              </v-col>
              <v-col
                cols="12"
                md="7"
              >
                <v-text-field
                  readonly
                  outlined
                  dense
                  placeholder="仕入先コード"
                  :value="newSupplierID"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <SupplierID
                  @add_supplierlist="getaddSupplierList"
                ></SupplierID>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row align="center">
              <v-col
                cols="12"
                md="2"
              >
                <label>仕入先名</label>
              </v-col>
              <v-col
                cols="12"
                md="7"
              >
                <v-text-field
                  readonly
                  outlined
                  dense
                  placeholder="仕入先名"
                  :value="newSupplierName"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card-actions>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>仕入日</label>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="2"
                    lg="3"
                    md="4"
                  >
                    <v-menu
                      v-model="dateStartMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          placeholder="From"
                          dense
                          hide-details
                          outlined
                          readonly
                          v-bind="attrs"
                          clearable
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        @input="dateStartMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>支払年月</label>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="2"
                    lg="3"
                    md="4"
                  >
                    <v-menu
                      v-model="dateEndMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          placeholder="To"
                          dense
                          hide-details
                          outlined
                          readonly
                          v-bind="attrs"
                          clearable
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        @input="dateEndMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row align="center" class="py-12">
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                class="mx-2"
                :to="{ name: 'purchase-pay', params: { query: this.$route.query } }"
              >
                <span>Submit</span>
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                :to="{ name: 'purchase-pay', params: { query: this.$route.query } }"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDotsHorizontal,
} from '@mdi/js'
import SupplierID from '../components/SupplierID.vue'

export default {
  components: {
    SupplierID,
  },
  data: () => ({
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    newSupplierList: [],
    newSupplierName: '',
    newSupplierID: '',
    icons: {
      mdiDotsHorizontal,
    },
  }),
  watch: {
  },
  methods: {
    getaddSupplierList(addCusList) {
      this.newSupplierList = addCusList
      this.newSupplierName = this.newSupplierList[0].supplier_name
      this.newSupplierID = this.newSupplierList[0].supplier_code
    },
  },
}
</script>
<style>
.ql-editor{
  min-height: 120px;
}
</style>
